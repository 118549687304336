@charset "UTF-8";

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/NotoSansCJKjp-Regular.woff2') format('woff2'),
        url('../../fonts/NotoSansCJKjp-Regular.woff') format('woff'),
        url('../../fonts/NotoSansCJKjp-Regular.ttf')  format('truetype'),
        url('../../fonts/NotoSansCJKjp-Regular.eot') format('embedded-opentype');
}
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/NotoSansCJKjp-Bold.woff2') format('woff2'),
        url('../../fonts/NotoSansCJKjp-Bold.woff') format('woff'),
        url('../../fonts/NotoSansCJKjp-Bold.ttf')  format('truetype'),
        url('../../fonts/NotoSansCJKjp-Bold.eot') format('embedded-opentype');
}

body,
table,
input, textarea, select, option {
	font-family: 'Noto Sans Japanese', 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

a {
    &:link {
        color: $linkColor1;
        text-decoration: underline;
    }

    &:visited {
        color: $linkColor1;
    }

    &:hover {
        font-weight: bold;
    }

    &:active {
        color: $linkColor1;
    }
}

object {
    pointer-events: none;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

body {
    background: #F9F9FA;
    overflow-x: hidden;
    color: $fontColor1;
    @include fontsize(14);
}
