@font-face {
  font-family: 'smart-o';
  src:
          url('../../fonts/smart-o.ttf?3lmi9s') format('truetype'),
          url('../../fonts/smart-o.woff?3lmi9s') format('woff'),
          url('../../fonts/smart-o.svg?3lmi9s#smart-o') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'smart-o' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.soi-message:before {
  content: "\e916";
}
.soi-link:before {
  content: "\e915";
}
.soi-arrow2-top:before {
  content: "\e913";
}
.soi-delete:before {
  content: "\e910";
}
.soi-setting:before {
  content: "\e911";
}
.soi-edit:before {
  content: "\e912";
}
.soi-user:before {
  content: "\e914";
}
.soi-users:before {
  content: "\e905";
}
.soi-spinner1:before {
  content: "\e90f";
}
.soi-spinner2 .path1:before {
  content: "\e90d";
  color: rgb(68, 68, 68);
  opacity: 0.4;
}
.soi-spinner2 .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(68, 68, 68);
}
.soi-close:before {
  content: "\e90c";
}
.soi-arrow1-bottom:before {
  content: "\e90b";
}
.soi-plus:before {
  content: "\e90a";
}
.soi-check:before {
  content: "\e900";
}
.soi-user-circle:before {
  content: "\e901";
}
.soi-video:before {
  content: "\e902";
}
.soi-info:before {
  content: "\e903";
}
.soi-lock:before {
  content: "\e904";
}
.soi-talking:before {
  content: "\e906";
}
.soi-meeting:before {
  content: "\e907";
}
.soi-private-micro:before {
  content: "\e908";
}
.soi-private:before {
  content: "\e909";
}
